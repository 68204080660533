import * as React from "react";
import "@fontsource/montserrat";
import * as Styles from './styles/tockePrograma.module.scss';

import divider from "./icons/DIVIDER.png";
//SLIKE
import VREDNOTE from "./icons/VREDNOTE.png";
import PEDAGOG from "./icons/PEDAGOG_JE_POKLIC.png";
import RAZISKOVALEC from "./icons/RAZISKOVALEC-VIZIONAR.png";
import STUDENT from "./icons/STUDENT_NAJ_BO.png";
import ZNANJE from "./icons/GOSPODARSTVO-OGLEDALO.png";
import NOVOGRADNJA from "./icons/NOVOGRADNJA.png";
import INFORMACIJA from "./icons/INFORMIRANOST_POVEZUJE.png";
import SVET from "./icons/ODPRTI_V_SVET_ZNANJA.png";
import LJUDJE from "./icons/FS_SMO_LJUDJE.png";

const tocke = [
    {
        "title1":"VRED",
        "title2":"NOTE",
        "text":`KULTURA DIALOGA: POSLUŠATI IN SLIŠATI
        ZAUPANJE, SODELOVANJE, SPODBUDA IN POMOČ
        STIČIŠČE LJUDI VSEH GENERACIJ IN STROK
        PRIPADNOST FS
        ZDRAVO DELOVNO OKOLJE
        ZDRAV DUH V ZDRAVEM TELESU`,
        "icon": VREDNOTE,
    },
    {
        "title1":"PEDAGOG JE PO",
        "title2":"KLIC",
        "text":`
        PREHOD NA NOV ŠTUDIJSKI PROGRAM
        DIGITALIZACIJA ALI DOBRA STRAN KORONE
        SODELOVANJE ŠTUDENTOV V PROJEKTIH
        UREJENE PRAKSE
        ORGANIZIRANE EKSKURZIJE`,
        "icon": PEDAGOG,
    },
    {
        "title1":"RAZISKOVALEC ",
        "title2":"VIZIONAR",
        "text":`
        POZICIONIRNE DELAVNICE
        PRIJAVE IN KOORDINIRANJE VELIKIH EU-PROJEKTOV
        POGODBE ZA NEDOLOČEN ČAS
        VKLJUČENOST V PEDAGOŠKO DELO
        SOFONANCIRANJE RAZISKOVALNE OPREME
        `,
        "icon": RAZISKOVALEC,
    },
    {
        "title1":"ŠTUDENT",
        "title2":" NAJ BO...",
        "text":`
        MOTIVIRAN IN RADOVEDEN
        VKLJUČEN V RAZISKOVALNO DELO
        POVEZAN V MEDNARODNO OKOLJE
        NOSILEC IZVIRNIH IDEJ
        OPREMLJEN S KOMPETENCAMI
        `,
        "icon": STUDENT,
    },
    {
        "title1":"GOSPODARSTVO - NAŠE ",
        "title2":"OGLEDALO",
        "text":`
        STRATEŠKI GOSPODARSKI PROJEKTI
        PRENOVA PROGRAMOV PRAKS ZA ŠTUDENTE
        ODPRTI LABORATORIJ
        VRHUNSKA STROKA V PREDAVALNICI
        GOSPODARSTVENIK V LABORATORIJU
        `,
        "icon": ZNANJE,
    },
    {
        "title1":"NOVO",
        "title2":"GRADNJA JE:",
        "text":`
        PRIORITETA
        PREGLEDNA, PRAVOČASNA
        PROJEKT ZA VEČ GENERACIJ
        DOLŽNOST DO ŠTUDENTOV
        DOLŽNOST DO ZAPOSLENIH
        DOLŽNOST DO GOSPODARSTVA IN DRUŽBE
        `,
        "icon": NOVOGRADNJA,
    },
    {
        "title1":"INFORMIRANJE ",
        "title2":"POVEZUJE",
        "text":`
        INFORMATIVNI JUTRANJIK
        MESEČNI KOLEGIJ SKUPNIH SLUŽB
        PRENOVA SPLETNE STRANI
        ENOVIT DIGITALNI SISTEM POSLOVANJA
        `,
        "icon": INFORMACIJA,
    },
    {
        "title1":"ODPRTI V SVET ",
        "title2":"ZNANJA",
        "text":`
        INTERNACIONALIZACIJA
        IZMENJAVA ŠTUDENTOV
        IZMENJAVA UČITELJEV IN RAZISKOVALCEV
        IZMENJAVA STROKOVNIH SODELAVCEV
        DOGODKI ALUMNI KLUBA FS
        `,
        "icon": SVET,
    },
    {
        "title1":"FS SMO ",
        "title2":"LJUDJE",
        "text":`
        OSEBNI KARIERNI NAČRTI ZA ZAPOSLENE
        ZAGOTAVLJANJE DELOVNIH POGOJEV IN SPODBUD PRI NAPREDOVANJU
        UREDITEV DELOVNO-PRAVNEGA POLOŽAJA
        ZAŠČITA IN KOMERCIALIZACIJA INTELEKTUALNE LASTNINE
        SPODBUJANJE PODJETNIŠKIH IDEJ
        `,
        "icon": LJUDJE,
    },
];

const tockePrograma = () => {
  const TockeList = tocke.map((t, index)=>{
    return(
        <div className={Styles.tocka}>
          <Tocka
            icon={t.icon}
            text={t.text}
            title1={t.title1}
            title2={t.title2}
            icon={t.icon}
          />
        </div>
    );
  });

  console.log(TockeList);
  return (
      <div>
      <div className={Styles.sectionTitle}>
        <h1>PROGRAMSKE USMERITVE</h1>
        <img src={divider}/>
      </div>
      <div className={Styles.tcontainer}>
      {TockeList}
    </div>
    </div>
  );
};

const Tocka = (props) => {
    const lines = props.text.split('\n').filter(x=> x !=='').map((l, index)=>{
        return(
            <p>{l}</p>
        );
    });
  return(
      <div className={Styles.wrap}>
      <div className={Styles.icon}> <img src={props.icon}/></div>
        <div className={Styles.info}>
      <div className={Styles.header}>
        <h3>{props.title1}</h3>
        <h3>{props.title2}</h3>
      </div>
      <div className={Styles.text}>
        {lines}
      </div>
      </div>
    </div>
  );
};

export default tockePrograma;
