import * as React from "react";
import * as Styles from './styles/card.module.scss';


class LinkCard extends React.Component {
    constructor(props){
        super(props);
        this.handleClick=this.handleClick.bind(this);
    }

    handleClick(){
      const newWindow = window.open(this.props.link, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    render(){
        var Slika = this.props.display;
        return(

            <div onClick={this.handleClick}
                 className={Styles.card}>
              <div className={Styles.image}>
                <Slika />
              </div>
              <div className={Styles.title}>
                <h3>{this.props.title}</h3>
              </div>
            </div>
        );
    }
};


export default LinkCard;
