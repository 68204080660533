// extracted by mini-css-extract-plugin
export const section = "tockePrograma-module--section--Ao-3N";
export const container = "tockePrograma-module--container--1W9PU";
export const pageContent = "tockePrograma-module--pageContent--CCgDd";
export const footer = "tockePrograma-module--footer--1MMd_";
export const modal = "tockePrograma-module--modal--1BNAL";
export const header = "tockePrograma-module--header--3epfz";
export const sectionTitle = "tockePrograma-module--sectionTitle--1x0Sb";
export const text = "tockePrograma-module--text--2xaw1";
export const tcontainer = "tockePrograma-module--tcontainer--1EbqL";
export const wrap = "tockePrograma-module--wrap--jVShT";
export const icon = "tockePrograma-module--icon--32T5O";