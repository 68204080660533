import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as Style from "./styles/povezave.module.scss";
import divider from "./icons/DIVIDER.png";

import Card from "../components/card.jsx";
import VideoCard from "../components/videoCard.jsx";
import LinkCard from "../components/linkCard.jsx";

const FRANCI_PUSAVEC = ()=>{return(<StaticImage src="./images/FRANCI_PUSAVEC_VIDEO.jpg" alt="JANKO SLAVIČ"/>);};
const JANKO_SLAVIC = ()=>{return(<StaticImage src="./images/JANKO_SLAVIC_VIDEO.jpg" alt="JERNEJ KLEMENC"/>);};
const JERNEJ_KLEMENC = ()=>{return(<StaticImage src="./images/JERNEJ_KLEMENC_VIDEO.jpg" alt="FRANCI PUŠAVEC"/>);};

const vrhunske_kompetence_img = ()=>{return(<StaticImage src="./images/vrhunske_kompetence.jpg" alt="VRHUNSKE KOMPETENCE"/>);};
const vpeljava_novih_img= ()=>{return(<StaticImage src="./images/vpeljava_novih.jpg" alt="VPELJAVA NOVIH"/>);};
const odprti_laboratorij_img= ()=>{return(<StaticImage src="./images/odprti_laboratorij.jpg" alt="ODPRTI LABORATORIJ"/>);};
const novogradnja_img= ()=>{return(<StaticImage src="./images/novogradnja.jpg" alt="NOVOGRADNJA"/>);};
const predmeti_c_img= ()=>{return(<StaticImage src="./images/predmeti_c.jpg" alt="NOVOGRADNJA"/>);};
const predstavitev_programov = ()=>{return(<StaticImage src="./images/predstavitev_programov_fb.jpg" alt="PREDSTAVITEV PROGRAMOV"/>);};
const soocenje = ()=>{return(<StaticImage src="./images/soocenje.jpg" alt="SOOČENJE"/>);};
const snemanje_studijskega_procesa = ()=>{return(<StaticImage src="./images/snemanje_studijskega_procesa.jpg" alt="SNEMANJE STUDIJSKEGA PROCESA"/>);};
const ekskurzije = ()=>{return(<StaticImage src="./images/ekskurzije.jpg" alt="EKSKURZIJE"/>);};
const novogradnja_2_img = ()=>{return(<StaticImage src="./images/novogradnja_2.jpg" alt="NOVOGRANDJA 2"/>);};
const internacionalizacija_img = ()=>{return(<StaticImage src="./images/internacionalizacija.jpg" alt="INTERNACIONALIZACIJA"/>);};
const place_img = ()=>{return(<StaticImage src="./images/place.jpg" alt="INTERNACIONALIZACIJA"/>);};
const predmeti_c_2_img= ()=>{return(<StaticImage src="./images/predmeti_tipa_c_2.jpg" alt="PRENOVLJEN ŠTUDIJSKI PROGRAM"/>);};
const predmeti_c_3_img= ()=>{return(<StaticImage src="./images/predmeti_tipa_c_3.jpg" alt="PRENOVLJEN ŠTUDIJSKI PROGRAM"/>);};

const Povezave = () => {
    const cards = povezave.map((c, index)=>{
        if(c.type==="video"){
            return(
                <VideoCard
                  link={c.link}
                  title={c.title}
                  display={c.image}
                />
            );
        }else if (c.type==="link") {
          console.log("LINK CARD")
            return(
                <LinkCard
                  link={c.link}
                  title={c.title}
                  display={c.image}
                />
            )
        }
    });

  return(
      <div className={Style.pContainer}>
        <div className={Style.sectionTitle}>
          <h1>POVEZAVE</h1>
          <img src={divider}/>
          <h2> do uspešne prihodnosti FS</h2>
        </div>
        <div className={Style.wrap}>
          {cards}
        </div>
      </div>
  );
};


const povezave = [
    {
        "title":"Vključenost v raziskovalno dejavnost",
        "type": "video",
        "link":"https://www.youtube.com/embed/Vh31rujESMQ",
        "image":predmeti_c_3_img
    },
    {
        "title":"Prenovljen študijski program",
        "type": "video",
        "link":"https://www.youtube.com/embed/4ndUAX6xMC4",
        "image":predmeti_c_2_img
    },
    {
        "title":"Plače zaposlenih",
        "type": "video",
        "link":"https://www.youtube.com/embed/Vw5xbXL_kQI",
        "image":place_img
    },
    {
        "title":"Internacionalizacija",
        "type": "video",
        "link":"https://www.youtube.com/embed/t5xWipPBEzc",
        "image":internacionalizacija_img
    },
    {
        "title":"Novogradnja 2",
        "type": "video",
        "link":"https://www.youtube.com/embed/6INwjxRdk5s",
        "image":novogradnja_2_img
    },
    {
        "title":"Študijske ekskurzije",
        "type": "video",
        "link":"https://www.youtube.com/embed/AMUiDY6zii4",
        "image":ekskurzije
    },
    {
        "title":"Soočenje kandidatov za dekana",
        "type": "link",
        "link":"https://www.youtube.com/watch?v=cRP78Ha-7aA",
        "image":soocenje
    },
    {
        "title":"Predstavitev programov",
        "type": "link",
        "link":"https://www.facebook.com/watch/live/?v=845110466109975&ref=watch_permalink",
        "image":predstavitev_programov
    },
    {
        "title":"Snemanje študijskega procesa",
        "type": "video",
        "link":"https://www.youtube.com/embed/JN_XYk6Qy3Q",
        "image":snemanje_studijskega_procesa
    },
    {
        "title":"Novogradnja",
        "type": "video",
        "link":"https://www.youtube.com/embed/eddFvzHZCcc",
        "image":novogradnja_img
    },
    {
        "title":"Predmeti C",
        "type": "video",
        "link":"https://www.youtube.com/embed/EvjPX8mfONE",
        "image":predmeti_c_img
    },
    /*
    {
        "title":"Odprti laboratorij FS",
        "type": "video",
        "link":"https://www.youtube.com/embed/vEqqkSrUvQI",
        "image":odprti_laboratorij_img
    },
    {
        "title":"Vpeljava novih študijskih programov",
        "type": "video",
        "link":"https://www.youtube.com/embed/kqDmVTAYhAs",
        "image":vpeljava_novih_img
    },
    {
        "title":"Vrhunske kompetence za uspešno gospodarstvo",
        "type": "video",
        "link":"https://www.youtube.com/embed/SM54-yvDg9E",
        "image":vrhunske_kompetence_img
    }
    */
];


export default Povezave;
