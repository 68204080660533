import * as React from "react";
import * as Styles from './styles/card.module.scss';
import ReactModal from 'react-modal';

const modalStyles={
    content:{
        backgroundColor:"#000000aa",
        border:"None",
        padding:0,
        inset:"unset",
        borderRadius:0,
    },
    overlay:{
        backgroundColor:"#000000aa",
        display: "flex",
        flexDirection: "column",
        justifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        minHeight:"100vh"
    }
};


class VideoCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {isToggleOn: false};
        this.handleClick=this.handleClick.bind(this);
    }

    handleClick(){
        console.log("CLICK");
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }
    render(){
        var Slika = this.props.display;
        return(
            <div onClick={this.handleClick}
                 className={Styles.card}>
              <ReactModal
                style={modalStyles}
                isOpen={this.state.isToggleOn}>
                <div className={Styles.videoWrap}>
                  <iframe width="560"
                          height="315"
                          src={this.props.link}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen>
                  </iframe>
            </div>
              </ReactModal>
              <div className={Styles.image}>
                <Slika />
              </div>
              <div className={Styles.title}>
                <h3>{this.props.title}</h3>
              </div>
            </div>
        );
    }
};


export default VideoCard;
