import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as Style from "./styles/kandidatiVideo.module.scss";
import divider from "./icons/DIVIDER.png";

import Card from "../components/card.jsx";
import VideoCard from "../components/videoCandidateCard.jsx";
import LinkCard from "../components/linkCard.jsx";

const FRANCI_PUSAVEC = ()=>{return(<StaticImage src="./images/FRANCI_PUSAVEC_VIDEO.jpg" alt="JANKO SLAVIČ"/>);};
const JANKO_SLAVIC = ()=>{return(<StaticImage src="./images/JANKO_SLAVIC_VIDEO.jpg" alt="JERNEJ KLEMENC"/>);};
const JERNEJ_KLEMENC = ()=>{return(<StaticImage src="./images/JERNEJ_KLEMENC_VIDEO.jpg" alt="FRANCI PUŠAVEC"/>);};

const vrhunske_kompetence_img = ()=>{return(<StaticImage src="./images/vrhunske_kompetence.jpg" alt="VRHUNSKE KOMPETENCE"/>);};
const vpeljava_novih_img= ()=>{return(<StaticImage src="./images/vpeljava_novih.jpg" alt="VPELJAVA NOVIH"/>);};
const odprti_laboratorij_img= ()=>{return(<StaticImage src="./images/odprti_laboratorij.jpg" alt="ODPRTI LABORATORIJ"/>);};

const KandidatiVideo = () => {
    const cards = povezave.map((c, index)=>{
        if(c.type==="video"){
            return(
                <VideoCard
                  link={c.link}
                  title={c.title}
                  display={c.image}
                />
            );
        }else if (c.type==="link") {
          console.log("LINK CARD")
            return(
                <LinkCard
                  link={c.link}
                  title={c.title}
                  display={c.image}
                />
            )
        }
    });

  return(
      <div className={Style.pContainer}>
        <div className={Style.wrap}>
          {cards}
        </div>
      </div>
  );
};


const povezave = [
    {
        "title":"Odprti laboratorij FS",
        "type": "video",
        "link":"https://www.youtube.com/embed/vEqqkSrUvQI",
        "image":odprti_laboratorij_img
    },
    {
        "title":"Vpeljava novih študijskih programov",
        "type": "video",
        "link":"https://www.youtube.com/embed/kqDmVTAYhAs",
        "image":vpeljava_novih_img
    },
    {
        "title":"Vrhunske kompetence za uspešno gospodarstvo",
        "type": "video",
        "link":"https://www.youtube.com/embed/SM54-yvDg9E",
        "image":vrhunske_kompetence_img
    }
];


export default KandidatiVideo;
