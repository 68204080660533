import * as React from "react";

import * as Style from './styles/footer.module.scss';
import facebookGeneric from "./icons/facebook-white.png";


const footer = () => {
    return(
        <div className={Style.footer}>
          <p>
            © 2021 | Mihael Sekavčnik | www.mihael.sekavcnik.com | Vse pravice pridržane
          </p>
        </div>
    );
};


const Separator = () =>{
  return(
      <li className={Style.separator}>
        |
      </li>
  )
}


const Footer = () => {
  return (
    <footer>
    <ul>
      <li>
      &copy; 2021
      </li>
      <Separator />
      <li>
        Mihael Sekavčnik
      </li>
      <Separator />
      <li>
        <div className={Style.social}>
        <a target="_blank" rel="noopener noreferrer" 
           href="https://www.facebook.com/prof.dr.mihael.sekavcnik">
          <img alt="social-icon-facebook" src={facebookGeneric} />
        </a>
        </div>
      </li>
      <Separator />

      <li>
        <div className={Style.social}>
          <a target="_blank" rel="noopener noreferrer" 
             href="https://lte.fs.uni-lj.si/">
            Laboratorij za Termoenergetiko
          </a>
        </div>
      </li>
      <Separator />
      <li>
      <a href="https://creativecommons.org/licenses/by-nc/4.0/">CC BY_NC 4.0</a>
      </li>
    </ul>
    </footer>
  );
};


export default Footer;
