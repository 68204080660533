import React from "react";

import * as Style from "./styles/embeddedVideo.module.scss";


import divider from "./icons/DIVIDER.png";

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div>
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
);


const EmbeddedVideo = () => {
    return(

        <div className={Style.sContainer}>
          <div className={Style.wrap}>
            <iframe
              src="https://www.youtube.com/embed/72BHkQZWo68?autoplay=0&showinfo=0&controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
    );
};


export default EmbeddedVideo;
