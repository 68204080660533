import * as React from "react";

import * as FirstImageStyles from './styles/firstImage.module.scss';
import MIHAEL from "./images/MIHAEL.jpg";

import "@fontsource/montserrat/500.css";

import { StaticImage } from "gatsby-plugin-image";


const firstImage = () => {
  return (
    <div className={FirstImageStyles.imageSection}>
      <div className={FirstImageStyles.imageContainer}>
        {/*<img src={MIHAEL}/>*/}
        <StaticImage src="./images/MIHAEL_1.jpg" alt="prof. dr. Mihael Sekavčnik"/>

      </div>
    </div>
  )
};


export default firstImage;


