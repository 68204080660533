import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";

import * as Style from "./styles/sodelavci.module.scss";
import divider from "./icons/DIVIDER.png";

const FRANCI_PUSAVEC = ()=>{return(<StaticImage src="./images/FRANCI_PUSAVEC.jpg" alt="JANKO SLAVIČ"/>);};
const JANKO_SLAVIC = ()=>{return(<StaticImage src="./images/JANKO_SLAVIC.jpg" alt="JERNEJ KLEMENC"/>);};
const JERNEJ_KLEMENC = ()=>{return(<StaticImage src="./images/JERNEJ_KLEMENC.jpg" alt="FRANCI PUŠAVEC"/>);};

const prodekani = [
    {
        "name": "JANKO SLAVIČ",
        "text": `
        KANDIDAT ZA PRODEKANA
        ZA ZNANSTVENO RAZISKOVALNO
        DEJAVNOST IN MEDNARODNO
        SODELOVANJE
         `,
        "image": JANKO_SLAVIC,
    },
    {
        "name": "JERNEJ KLEMENC",
        "text": `
        KANDIDAT ZA PRODEKANA
        ZA PEDAGOŠKO DEJAVNOST
        II. in III. STOPNJE
         `,
        "image":JERNEJ_KLEMENC,
    },
    {
        "name": "FRANCI PUŠAVEC",
        "text": `
        KANDIDAT ZA PRODEKANA
        ZA PEDAGOŠKO DEJAVNOST
        I. STOPNJE
         `,
        "image": FRANCI_PUSAVEC,
    }
];

const Sodelavec = (props) => {
    const text = props.text.split('\n').
          filter(x=> x!=='').map((l,index)=>{
              console.log(l);
              return(
                  <p>{l}</p>
              );
    });
    console.log(props.image);
    var Slika = props.image;
    return(
        <div className={Style.sodelavec}>
          <div className={Style.imageDiv}>
            <Slika />
          </div>
          <div className={Style.text}>
            <h3>{props.name}</h3>
            {text}
          </div>
        </div>
    );
};

const Sodelavci= () => {
    const proDekani = prodekani.map((d, index)=>{
        console.log(index);
        return(
            <Sodelavec
              name={d.name}
              text={d.text}
              image={d.image}
            />
        );
    });

  return(
      <div className={Style.sContainer}>
        <div className={Style.sectionTitle}>
          <h1>MOJI OŽJI <span>SO</span>DELAVCI</h1>
          <img src={divider}/>
          <h2> MLADI, KOMPETENTNI, ODLOČNI - USMERJENI V PRIHODNOST</h2>
        </div>
        <div className={Style.wrap}>
          {proDekani}
        </div>
      </div>
  );
};

export default Sodelavci;
