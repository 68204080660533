import * as React from "react";
import FirstImage from '../sections/firstImage.js';
import TockePrograma from '../sections/tockePrograma.js';
import Sodelavci from '../sections/sodelavci.jsx';
import EmbeddedVideo from '../sections/embeddedVideo.jsx';
import EmbeddedVideo2 from '../sections/embeddedVideo_2.jsx';
import EmbeddedVideo3 from '../sections/embeddedVideo_3.jsx';
import Povezave from '../sections/povezave.js';
import KandidatiVideo from '../sections/kandidatiVideo.js';
import Footer from '../sections/footer.jsx';

import * as Styles from './styles/index.module.scss';
//import MainStyles from '../styles/main.scss';


// markup
const IndexPage = () => {
  return (
    <main>
      <div className={Styles.pageContent}>
      <FirstImage/>
      <Sodelavci/>
      <EmbeddedVideo2/>
      <EmbeddedVideo3/>
      <EmbeddedVideo/>
      <KandidatiVideo/>
      <TockePrograma/>
      <Povezave />
      </div>
      <Footer/>
    </main>
  );
};

export default IndexPage;
